@font-face {
  font-family: 'Graphik-Regular';
  src:  url('../fonts/Graphik-Regular.woff') format('woff'),
        url('../fonts/Graphik-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik-Medium';
  src:  url('../fonts/Graphik-Medium.woff') format('woff'),
        url('../fonts/Graphik-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

