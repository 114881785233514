html,
body {
  margin: 0;
  min-height: 100%;
}

html {
  -webkit-text-size-adjust: none;
}

body {
  min-height: 100%;
  min-height: var(--vh);
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  -webkit-overflow-scrolling: touch;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

::-moz-focus-inner {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

button {
  background: none;
  border: 0;
  outline: none;
}

dialog {
  border: none;
}

.page-main {
  width: 100%;
  position: relative;
  z-index: 5;
}

.page {
  position: relative;
  top: 0;
  right: 0;
}

#root {
  position: relative;
  z-index: 5;
  width: 100%;
}

p {
  margin-top: 0;
}

ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.content-root {
  position: relative;
  z-index: 10;
}

#webgl {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background-canvas {
  position: fixed !important;
  z-index: -1;
  top: 0;
  left: 0;
  height: calc(
    var(--vh) + 150px
  ) !important; /* Non --vh perché si deve ridimensionare */
  width: 100vw !important;
}

#page {
  display: none;
}

.route-container {
  position: relative;
}

.route-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
}

/* REMOVE RECAPTCHA BADGE */
.grecaptcha-badge {
  visibility: collapse !important;
}

/* ACCESSIBILITY KEYBOARD NAVIGATION */
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.js-focus-visible .focus-visible {
  outline: -webkit-focus-ring-color auto 5px;
}

.js-focus-visible input.focus-visible,
.js-focus-visible textarea.focus-visible {
  outline: none;
}

/* Solve "Unable to preventDefault inside passive event listener invocation." Error */
.slick-list,
.slick-track {
  touch-action: none;
}

/* Remove Mapbox Copy */
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

.upgrade-browser {
  color: #485cc7;
  font-size: 20px;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
